

<mat-card>

    <div class="whole_wrpr">

      <div class="common_width">
        
        <div class="nda_flx_wrpr">

           
       

        <div class="nda_left">

       
         <div class="nda_logo">
            <img src="https://all-frontend-assets.s3.amazonaws.com/beto_paredes_nda_angular/contract-form/nda_beto_logo.webp"/>
         </div>

         <div class="nda_hdr_txt">
            <h1><span class="wlcm_spn">Welcome to </span> <span>Our DecisionDOC, Inc, Clinical Speed and Rapid Response Diagnostics NDA agreement for the February 13th, 2024 software platform exposition!</span></h1>
         </div>

         <div class="nda_arrow_txt">
            <p>Please fill out this form and it will push to our quick signature NDA. Once you have signed you will be automatically added to the webinar for our software demonstration on the 13th!</p>

         </div>

        </div>
 
        <div class="nda_right">
        
         <div class="nda_form_wrpr">
            <div class="nda_hdr">
                <div class="nda_h1">
                    <h1>Interested? </h1>
                </div>

                <h2 class="nda_h2">Please fill <br>
                    out this form</h2>
            </div>

            <div class="form_sec">
                <lib-showform 
                [formdata]="configFormData"
                  [formfieldrefresh]="formfieldrefresh" [formfieldrefreshdata]="formfieldrefreshdata"
                  (onFormFieldChange)="listenFormFieldChange($event)">
                </lib-showform>
                <ng-container *ngIf="addFormLoader">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </ng-container>
            </div>
           

         </div>

       </div>
      </div>
     </div>
    </div>
    
    <div class="nda_footer">
        <div class="common_width">
            <div class="nda_ftr_p">
                <p>© 2024 Beto Paredes, LLC. All rights reserved.</p>
            </div>
        </div>
    </div>
    
</mat-card>
