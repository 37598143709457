<mat-card class="nda_main_wrpr">
  <div class="cmn_width">
    <form class=""  name="ndaform" [formGroup]="ndaform" *ngIf = "flagnew">
    <div class="nda_form_wrpr">
      <div class="nda_beto_logo">
        <img
          src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
        />
      </div>

      <div class="nda_hdr"><h1>Non-Disclosure Agreement (NDA)</h1></div>

      <div class="nda_txt">
        <p>
          <span
            >This Non-disclosure Agreement ("Agreement") is made effective as of January 29, 2024 ("Effective Date"), by and between Decision Doc, Inc, dba Clinical Speed and dba Rapid Response Diagnostics ("Owner"), of
            1309 Coffeen Avenue STE 1200, Sheridan, Wyoming 82801, and</span
          >

          
          <strong class="strong">
            <mat-form-field class="nda_full_width rght_allgn"
              ><input matInput type="text" name="firstName" formControlName="firstName" (input)="filedval($event)" #myElem1 [value]= "ndaform.value.firstName" required
            /></mat-form-field>
            <mat-error class="error" *ngIf="errors?.firstName">This field is required</mat-error>
          </strong>
          <strong class="strong">
            <mat-form-field class="nda_full_width lft_allgn"
              ><input matInput type="text" name="lastName" formControlName="lastName" (input)="filedval($event)" #myElem1 [value]= "ndaform.value.lastName" required 
            /></mat-form-field>
            <mat-error class="error" *ngIf="errors?.lastName">This field is required</mat-error>
          </strong>
          <span>("Recipient"), of</span>
          <span>
            <strong class="strong">
              <mat-form-field class="nda_full_width1 nda_full_width"
                ><input matInput type="text" name="address" formControlName="address" (input)="filedval($event)" #myElem2  [value]= "ndaform.value.address" required 
              /></mat-form-field>
              <mat-error class="error" *ngIf="errors?.address">This field is required</mat-error>
              </strong
            >
            ,
            <strong class="strong">
              <mat-form-field class="nda_full_width2 nda_full_width"
                ><input matInput type="text" name="city" formControlName="city" (input)="filedval($event)" #myElem3 [value]= "ndaform.value.city" required 
              /></mat-form-field>
              <mat-error class="error" *ngIf="errors?.city">This field is required</mat-error>
              </strong
            ></span
          >
          <span
            ><strong class="strong">
              <mat-form-field class="nda_full_width3 nda_full_width"
                ><input matInput type="text" name="state" formControlName="state" (input)="filedval($event)" #myElem4 [value]= "ndaform.value.state" required 
              /></mat-form-field>
              <mat-error class="error" *ngIf="errors?.state">This field is required</mat-error>
              </strong
            ></span
          >,
          <span
            ><strong class="strong">
              <mat-form-field class="nda_full_width4 nda_full_width"
                ><input matInput type="text" name="zip" formControlName="zip" (input)="filedval($event)" #myElem4 [value]= "ndaform.value.zip" required 
              /></mat-form-field>
              <mat-error class="error" *ngIf="errors?.zip">This field is required</mat-error>
              </strong
            ></span
          >.
        </p>
      </div>

      <div class="nda_txt">
        <p>
          Information will be disclosed on a system related to new systems,
          protocols, and software solutions that will benefit the medical lab
          industry and its providers.  Parts of the systems are under patent
          pending status. The Owner has requested, and the Recipient agrees that
          the Recipient will protect the confidential material and information
          that may be disclosed between the Owner and the Recipient. Therefore,
          the parties agree as follows:
        </p>
      </div>

      <div class="nda_txt">
        <p>
          <b>I. Confidential Information.</b> The term "Confidential
          Information" means any information or material that is proprietary to
          the Owner, whether owned or developed by the Owner, which is not
          generally known other than by the Owner, and which the Recipient may
          obtain through any direct or indirect contact with the Owner.
          Regardless of whether specifically identified as confidential or
          proprietary, Confidential Information shall include any information
          provided by the Owner concerning the business, technology, and
          information of the Owner and any third party with which the Owner
          deals, including, without limitation, business records and plans,
          trade secrets, technical data, product ideas, contracts, financial
          information, pricing structure, discounts, computer programs and
          listings, source code and object code, copyrights and intellectual
          property, inventions, sales leads, strategic alliances, partners, and
          customer and client lists. The nature of the information and the
          manner of disclosure are such that a reasonable person would
          understand it to be confidential.
        </p>
      </div>

      <div class="nda_txt">
        <p> <b>Confidential Information does not include:</b></p>
        <div class="nda_bullet">
          <p>
            matters of public knowledge that result from disclosure by the
            Owner;
          </p>
          <p>
            information rightfully received by the Recipient from a third party
            without a duty of confidentiality;
          </p>
          <p>information independently developed by the Recipient;</p>
          <p>information disclosed by operation of law;</p>
          <p>
            and any other information that both parties agree in writing is not
            confidential.
          </p>
        </div>
      </div>

      <div class="nda_txt">
        <p>
          <b>II. Protection of Confidential Information.</b> The Recipient
          understands and acknowledges that the Confidential Information has
          been developed or obtained by the Owner by the investment of
          significant time, effort, and expense, and that the Confidential
          Information is a valuable, special, and unique asset of the Owner
          which provides the Owner with a significant competitive advantage and
          needs to be protected from improper disclosure. In consideration for
          the receipt by the Recipient of the Confidential Information, the
          Recipient agrees as follows:
        </p>

        <div class="para">
          <p>
            (a) <b>No Disclosure:</b> The Recipient will hold the Confidential
            Information in confidence and will not disclose the Confidential
            Information to any person or entity without the prior written
            consent of the Owner.
          </p>

          <p>
            (b) <b> No Copying/Modifying:</b> The Recipient will not copy or
            modify any Confidential Information without the prior written
            consent of the Owner.
          </p>

          <p>
            (c) <b> Unauthorized Use:</b> The Recipient shall promptly advise
            the Owner if the Recipient becomes aware of any possible
            unauthorized disclosure or use of the Confidential Information.
          </p>

          <p>
            (d) <b> Application to Employees:</b> The Recipient shall not
            disclose any Confidential Information to any employees of the
            Recipient, except those employees who are required to have the
            Confidential Information to perform their job duties in connection
            with the limited purposes of this Agreement. Each permitted employee
            to whom Confidential Information is disclosed shall sign a
            non-disclosure agreement substantially the same as this Agreement at
            the request of the Owner.
          </p>
        </div>
      </div>

      <div class="nda_txt">
        <p>
          <b>III. Unauthorized Disclosure of Information - Injunction:</b> If it
          appears that the Recipient has disclosed (or has threatened to
          disclose) Confidential Information in violation of this Agreement, the
          Owner shall be entitled to an injunction to restrain the Recipient
          from disclosing the Confidential Information in whole or in part. The
          Owner shall not be prohibited by this provision from pursuing other
          remedies, including a claim for losses and damages.
        </p>

        <p>
          <b>IV. Non-Circumvention.</b> For five (5) years after the end of the
          term of this Agreement, the Recipient will not attempt to do business
          with, or otherwise solicit any business contacts found or otherwise
          referred by the Owner to the Recipient for circumventing, the result
          of which shall be to prevent the Owner from realizing or recognizing a
          profit, fees, or otherwise, without the specific written approval of
          the Owner. If such circumvention shall occur the Owner shall be
          entitled to any commissions due under this Agreement or relating to
          such transaction.
        </p>

        <p>
          <b>V. Relationship of Parties.</b> Neither party has an obligation
          under this Agreement to purchase any service or item from the other
          party, or commercially offer any products using or incorporating the
          Confidential Information. This Agreement does not create any agency,
          partnership, or joint venture.
        </p>

        <p>
          <b>VI. No Warranty.</b> The Recipient acknowledges and agrees that the
          Confidential Information is provided on an "AS IS" basis. THE OWNER
          MAKES NO WARRANTIES, EXPRESS OR IMPLIED, CONCERNING THE CONFIDENTIAL
          INFORMATION AND HEREBY EXPRESSLY DISCLAIMS ANY AND ALL IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. IN
          NO EVENT SHALL THE OWNER BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL,
          OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR ARISING OUT OF THE
          PERFORMANCE OR USE OF ANY PORTION OF THE CONFIDENTIAL INFORMATION. The
          Owner does not represent or warrant that any product or business plans
          disclosed to the Recipient will be marketed or carried out as
          disclosed, or at all. Any actions taken by the Recipient in response
          to the disclosure of the Confidential Information shall be solely at
          the risk of the Recipient.
        </p>

        <p>
          <b>VII. Limited License To Use.</b> The Recipient shall not acquire
          any intellectual property rights under this Agreement except the
          limited right to use as set forth above. The Recipient acknowledges
          that, as between the Owner and the Recipient, the Confidential
          Information and all related copyrights and other intellectual property
          rights, are (and always will be) the property of the Owner, even if
          suggestions, comments, and/or ideas made by the Recipient are
          incorporated into the Confidential Information or related materials
          during the period of this Agreement.
        </p>

        <p>
          <b>IX. Indemnity.</b> Each party agrees to defend, indemnify, and hold
          harmless the other party and its officers, directors, agents,
          affiliates, distributors, representatives, and employees from any
          third-party claims, demands, liabilities, costs and expenses,
          including reasonable attorney's fees, costs, and expenses resulting
          from the indemnifying party's material breach of any duty,
          representation, or warranty under this Agreement.
        </p>

        <p>
          <b>X. Attorney's Fees.</b> In any legal action between the parties
          concerning this Agreement, the prevailing party shall be entitled to
          recover reasonable attorney's fees and costs.
        </p>

        <p>
          <b>XI. Term.</b> The obligations of this Agreement shall survive 3
          years from the Effective Date or until the Owner sends the Recipient
          written notice releasing the Recipient from this Agreement. After
          that, the Recipient must continue to protect the Confidential
          Information that was received during the term of this Agreement from
          unauthorized use or disclosure for an additional 3 years.
        </p>

        <p>
          <b>XII. General Provisions.</b> This Agreement sets forth the entire
          understanding of the parties regarding confidentiality. Any amendments
          must be in writing and signed by both parties. This Agreement shall be
          construed under the laws of the State of Wyoming. This Agreement shall
          not be assignable by either party. Neither party may delegate its
          duties under this Agreement without the prior written consent of the
          other party. The confidentiality provisions of this Agreement shall
          always remain in full force and effect by the terms of this Agreement.
          If any provision of this Agreement is held to be invalid, illegal, or
          unenforceable, the remaining portions of this Agreement shall remain
          in full force and effect and construed to best effectuate the original
          intent and purpose of this Agreement.
        </p>

        <p></p>
      </div>

      <!-- <div class="nda_flx">
        <div class="by">
          <p>
            <span>By: </span>
            <span
              ><strong class="strong1">
                <mat-form-field class="nda_full_width11 nda_full_width"
                  ><input matInput type="text" name="by1" formControlName="by1" (input)="filedval($event)" [value]= "ndaform.value.by1"
                /></mat-form-field>
                <mat-error class="error" *ngIf="errors?.by1">This field is required</mat-error>
                </strong
              ></span
            >
          </p>
        </div>

        <div class="date">
          <p>
            <span>Date: </span>
            <span>{{ today | date : "shortDate" }}</span>
          </p>
        </div>
      </div> -->

      <div class="receipt"><h4>The Receipt :</h4></div>

      <div class="nda_flx">
        <div class="by">
          <p>
            <span>By: </span>
            <span
              >
              <strong class="strong">
                <mat-form-field class="nda_full_width nda_full_width13"
                  ><input matInput type="text" name="firstName" formControlName="firstName" (input)="filedval($event)" #myElem1 [value]= "ndaform.value.firstName" readonly="true"
                /></mat-form-field>
                <mat-error class="error" *ngIf="errors?.firstName">This field is required</mat-error>
              </strong>

              <strong class="strong">
                <mat-form-field class="nda_full_width nda_full_width13"
                  ><input matInput type="text" name="lastName" formControlName="lastName" (input)="filedval($event)" #myElem1 [value]= "ndaform.value.lastName" readonly="true"
                /></mat-form-field>
                <mat-error class="error" *ngIf="errors?.lastName">This field is required</mat-error>
              </strong>
                
                </span
            >
          </p>
        </div>

        <div class="date">
          <p>
            <span>Date: </span>
            <span>{{ today | date: 'dd/MM/yyyy'}}</span>
          </p>
        </div>
      </div>

      <div class="sign nda_txt">
        <p><span class="signnt">Signature:</span>
        <span
          ><strong class="strong1">
            <mat-form-field class="nda_full_width12 nda_full_width"
              ><input matInput type="text" name="contract_signed_by" formControlName="contract_signed_by" (input)="filedval($event)" required
            /></mat-form-field>
            <mat-error class="error" *ngIf="errors?.contract_signed_by">Signature is required</mat-error>
            </strong
          ></span
        ></p>
      </div>

      <button class="submit_wrapper" (click)="formsubmit()" type="button">Submit</button>

      <ng-container *ngIf="addFormLoader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>



    </div>
    </form>
  </div>
</mat-card>
