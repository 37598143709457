import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiserviceService } from '../services/apiservice.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-nda-form',
  templateUrl: './nda-form.component.html',
  styleUrls: ['./nda-form.component.css'],
})
export class NdaFormComponent {
  @ViewChild('myElem1') MyProp1!: ElementRef;
  @ViewChild('myElem2') MyProp2!: ElementRef;
  @ViewChild('myElem3') MyProp3!: ElementRef;
  @ViewChild('myElem4') MyProp4!: ElementRef;

  public ndaform: FormGroup;
  public errors: any;
  public submitData: any;
  public flag: boolean = false;
  public today: number = Date.now();
  public routeID: any;
  public userData: any;
  public name: any;
  public flagnew: boolean = false;
  public addFormLoader: any = false;

  constructor(
    public activateRoute: ActivatedRoute,
    private cookieService: CookieService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    private contract: FormBuilder,
    public apiService: ApiserviceService,
  ) {
    console.log('previous+++++++++++++',environment);
    this.ndaform = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),

      address: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      // by1: new FormControl(''),
      // by2: new FormControl(''),
      statecontract_signed_by: new FormControl(''),
    });
    this.activateRoute.queryParams.subscribe((params: any) => {
      console.log('params', params);
      this.routeID = params?.id;
    });

    console.log('this.routeID', this.routeID);

    this.apiService
      .getHttpDataPost('go-to-webiner/register-nda-user-webinar-preview', {
        _id: this.routeID,
      })
      .subscribe({
        next: (response) => {
          console.log('hit responsew===========>', response);
          let id = response?.results?._id;
          if (response.status === 'success') {
            this.userData = response?.results ? response.results : {};

            console.log('userData---------->', this.ndaform.value);

            this.ndaform = this.contract.group({
              firstName: [
                this.userData?.firstName ? this.userData.firstName : '',
                Validators.required,
              ],
              lastName: [
                this.userData?.lastName ? this.userData.lastName : '',
                Validators.required,
              ],

              address: [
                this.userData?.address ? this.userData.address : '',
                Validators.required,
              ],
              city: [
                this.userData?.city ? this.userData.city : '',
                Validators.required,
              ],
              state: [
                this.userData?.state ? this.userData.state : '',
                Validators.required,
              ],
              zip: [
                this.userData?.zip ? this.userData.zip : '',
                Validators.required,
              ],
              // by1: [ 'Beto Pardes', Validators.required,],
              // by2: [
              //   this.userData?.firstName
              //     ? this.userData.firstName + ' ' + this.userData.lastName
              //     : '',
              //   Validators.required,
              // ],
              contract_signed_by: [
                this.ndaform.value.contract_signed_by
                  ? this.ndaform.value.contract_signed_by
                  : '',
                Validators.required,
              ],
              contract_signed_on: [this.today, Validators.required],
            });

            this.flagnew = true;
          }
        },
        error: (error) => { },
      });
  }

  filedval(event: any) {
    console.log('hlw--------------', event.target.value, event.target.name);

    let name = event.target.name;
    console.log('contract===========>', this.contract);

    this.ndaform = this.contract.group({
      ...this.ndaform.value,
      [name]: event.target.value,
    });

    if (event.target.name == 'firstName') {
      this.errors = { ...this.errors, firstName: '' };
    }

    if (event.target.name == 'lastName') {
      this.errors = { ...this.errors, lastName: '' };
    }

    if (event.target.name == 'address') {
      this.errors = { ...this.errors, address: '' };
    }

    if (event.target.name == 'city') {
      this.errors = { ...this.errors, city: '' };
    }

    if (event.target.name == 'state') {
      this.errors = { ...this.errors, state: '' };
    }
    if (event.target.name == 'zip') {
      this.errors = { ...this.errors, zip: '' };
    }

    // if (event.target.name == 'by1') {
    //   this.errors = { ...this.errors, by1: '' };
    // }

    // if (event.target.name == 'by2') {
    //   this.errors = { ...this.errors, by2: '' };
    // }

    if (event.target.name == 'contract_signed_by') {
      this.errors = { ...this.errors, contract_signed_by: '' };
    }
  }

  ngOnInit() {
    console.log('asda', this.ndaform);
  }

  formsubmit() {
    console.log('value123======', this.ndaform.status);
    this.submitData = this.ndaform.value;

    console.log('value======', this.submitData, this.ndaform.value.firstName);



    if (this.ndaform.value.firstName == '') {
      console.log("hit error");

      this.MyProp1.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      this.errors = { ...this.errors, firstName: 'This field is required' };
      return
    }

    if (this.ndaform.value.lastName === '') {
      this.MyProp1.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      this.errors = { ...this.errors, lastName: 'This field is required' };
      return
    }

    if (this.ndaform.value.address === '') {
      this.MyProp2.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.errors = { ...this.errors, address: 'This field is required' };
      return
    }

    if (this.ndaform.value.city === '') {
      this.MyProp3.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.errors = { ...this.errors, city: 'This field is required' };
      return
    }

    if (this.ndaform.value.state === '') {
      this.MyProp4.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.errors = { ...this.errors, state: 'This field is required' };
      return
    }
    if (this.ndaform.value.zip === '') {
      this.MyProp4.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.errors = { ...this.errors, zip: 'This field is required' };
      return
    }
    // if (this.ndaform.value.by1 === '') {
    //   this.errors = { ...this.errors, by1: 'This field is required' };
    // }

    // if (this.ndaform.value.by2 === '') {
    //   this.errors = { ...this.errors, by2: 'This field is required' };
    // }

    if (this.ndaform.value.contract_signed_by === '') {
      this.errors = {
        ...this.errors,
        contract_signed_by: 'This field is required',
      };
      return
    }
    if (this.ndaform.status == 'INVALID') {
      console.log("this.w9Form=====>", this.ndaform);


      this.matSnackBar.open('There is field, you have forgot to fill up', "Ok", {
        duration: 3000
      })
    }
    else {
      this.addFormLoader = true;
      console.log("Hittt jhjh submit");

      // return
      this.flag = false;
      this.apiService
        .getHttpDataPost('go-to-webiner/register-nda-user-webinar-edit', {
          ...this.submitData,
          _id: this.routeID,
        })
        .subscribe({
          next: (response: any) => {
            if (response.status === 'success') {
              console.log('hittttttttttttttt', response);
              this.addFormLoader = false;
              let sourcename = sessionStorage.getItem('source');
              // sourcename + `?ndasigned=true`
              if (sourcename) {
                this.matSnackBar.open("Thank you for signing", 'Ok', {
                  duration: 3000,
                });
                sessionStorage.removeItem('source');
                // window.location.replace(`https://dev.frontend.betoparedes.com/watch-presentation?ndasign=true`);
                window.location.replace(environment.bp_redirectURl);
                

              } else {
                setTimeout(() => {
                  this.router.navigateByUrl(`/success-page`);
                }, 1000);
              }
            }
          },
          error: (error: any) => {
            this.matSnackBar.open('Something Went Wrong!', 'Ok', {
              duration: 3000,
            });
          },
        });
    }
  }
}
