<mat-card>


  <div class="success_page_main">

      <div class="success_img">
        <img
          src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
        />
      </div>

      <div class="success_txt">
             <h1> Thank You for Submitting </h1>
             <p>you will be automatically added to the webinar for our software demonstration on the 13th!</p>
      </div>

  </div>



</mat-card>