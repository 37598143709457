import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractFormComponent } from './contract-form/contract-form.component';
import { NdaFormComponent } from './nda-form/nda-form.component';
import { ResolveService } from './services/resolve.service';
import { SuccessPageComponent } from './success-page/success-page.component';

const routes: Routes = [
  { path: '', component: ContractFormComponent },
  { path: 'nda-form', component: NdaFormComponent ,
  resolve: { data: ResolveService },
  data: {
    requestcondition: {
        '_id':'_id'
    },
    endpoint: 'go-to-webiner/register-nda-user-webinar-preview',
  },
  },
  { path: 'success-page', component: SuccessPageComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
