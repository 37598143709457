import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { buffer, catchError, map, Observable, Subject, Subscription, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  public baseUrl: any = environment.api_url;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: '',
    }),
  };


  constructor(private http: HttpClient,public matSnackBar: MatSnackBar,) { }

  public getHttpDataPost(url: any, body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        this.matSnackBar.open(error.error.message,'', {
          duration: 3000
        });
        
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public getHttpData(url: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + url, this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        console.log('error --->', error);
        return throwError(() => error.error);
      })
    );
  }



  public getHttpDataPostWithoutBaseUrl (url: any,body:any): Observable<any> {
    return this.http.post<any>(url, body).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }
}
