import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContractFormComponent } from './contract-form/contract-form.component';
import { DemoMaterialModule } from './material.module';
import { ListingAngular15Module } from 'listing-angular15';
import { HttpClientModule } from '@angular/common/http';
import { NdaFormComponent } from './nda-form/nda-form.component';
import { SuccessPageComponent } from './success-page/success-page.component';


@NgModule({
  declarations: [
    AppComponent,
    ContractFormComponent,
    NdaFormComponent,
    SuccessPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    ListingAngular15Module,
    HttpClientModule

  ],
  exports:[
    DemoMaterialModule,
    ListingAngular15Module
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
})
export class AppModule { }
