import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiserviceService } from '../services/apiservice.service';

@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.css'],
})
export class ContractFormComponent {
  // public safeURL: any

  public configFormData = {};
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public dropdownvalue: any = [];
  public formLoader: boolean = false;
  public forUpdate: boolean = false;
  public landingpages: any = [];
  public formValue: any = null;
  public addFormLoader: any = false;
  public stateList: any = [];
  emailregex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;



  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    //  private apiservice: ApiservicesService,
    public matSnackBar: MatSnackBar,
    public activateRoute: ActivatedRoute,
    public router: Router,
    private _sanitizer: DomSanitizer,
    public apiService: ApiserviceService,
  ) {
    console.log('previous+++++++++++++',environment);
    activateRoute.queryParams.subscribe(
      params => {


        console.log('queryParams', params['source'])
        if (params['source']) {
          sessionStorage.setItem('source', params['source']);
        } else {
          sessionStorage.removeItem('source');
        }


      });

    console.log('Called Constructor', this.activateRoute.queryParams);

    // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${data.youtube_video_link}`);
  }

  ngOnInit() {
    this.initailForm();
    console.log('test===========', environment.stage);
    this.getStates()
  }


  getStates() {
    this.apiService.getHttpData('user/states').subscribe((response) => {
      if (response.results.res && response.results.res.length > 0) {
        response.results.res.forEach((e: any, i: number) => {
          let obj = { val: e.name, name: e.name };
          this.stateList.push(obj)
        })
      }
      console.log(this.stateList, "stateList")
    })
  }
  initailForm() {
    this.configFormData = {
      successmessage: 'Added Successfully !!',
      submittext: 'register now',
      apiUrl: 'environments.',
      resettext: 'Reset',
      canceltext: 'Cancel',
      hidereset: true,
      hidecancel: true,
      fields: [
        {
          label: 'First name',
          name: 'firstName',
          value: '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'First name is Required' },
          ],
        },
        {
          label: 'Last name',
          name: 'lastName',
          value: '',
          type: 'text',
          classname: 'aaa',
          validations: [{ rule: 'required', message: 'Last name is Required' }],
        },
        {
          label: 'Company',
          name: 'company',
          value: '',
          type: 'text',
          classname: 'aaa',
          // validations: [{ rule: 'required', message: 'Company is Required' }],
        },
        {
          label: 'Telephone No',
          name: 'phone',
          value: '',
          type: 'numberformat',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Telephone No is Required' },
            {
              rule: 'minLength',
              value: 14,
              message: 'Formating Phone Number min 10',
            },
          ],
        },
        {
          label: 'Email',
          name: 'email',
          value: '',
          type: 'email',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Email is Required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },
        {
          label: 'Address',
          name: 'address',
          value: '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Address is Required' },

          ],
        },
        {
          label: "State",
          name: "state",
          type: 'select',
          val: this.stateList,
          // value:[2021,2022],
          multiple: false,
          value: '',
          validations: [
            { rule: 'required', message: 'State is Required' }
          ],

        },
        {
          label: 'City',
          name: 'city',
          value: '',
          type: 'text',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'City is Required' },

          ],
        },
        {
          label: 'Zip',
          name: 'zip',
          value: '',
          type: 'number',
          classname: 'aaa',
          validations: [
            { rule: 'required', message: 'Zip is Required' },

            {
              rule: 'minLength',
              value: 5,
              message: 'Formating zip Number min 5',
            }
            // {
            //   rule: 'pattern',
            //   message: 'Must be a valid zip code',
            // },

          ],
        },
      ],
    };
  }

  listenFormFieldChange(val: any) {
    console.log('val=====', val);

    this.formValue = val.fromval;
    console.log('formValue===========>', this.formValue);

    if (val.fieldval == 'success') {
      this.addFormLoader = true
      this.apiService
        .getHttpDataPost('go-to-webiner/register-nda-user-webinar-add', this.formValue)
        .subscribe({
          next: (response) => {
            console.log('hit===========>', response);
            let id = response?.results?._id
            if (response.status === 'success') {
              this.addFormLoader = false
              this.matSnackBar.open("Thank You For Submitting", 'Ok', {
                duration: 3000,
              });

              if (response?.results?.alreadyExists) {
                let sourcename = sessionStorage.getItem('source');
                // sourcename + `?ndasigned=true`
                if (sourcename) {
                  sessionStorage.removeItem('source');
                  // window.location.replace(`https://dev.frontend.betoparedes.com/watch-presentation?ndasign=true`);
                  window.location.replace(environment.bp_redirectURl);

                } else {
                  setTimeout(() => {

                    this.router.navigateByUrl(`/nda-form?id=${id}`)

                  }, 3000);
                }
              } else {
                setTimeout(() => {

                  this.router.navigateByUrl(`/nda-form?id=${id}`)

                }, 3000);
              }


            }
          },
          error: (error) => {
            this.addFormLoader = true
            // this.addFormLoader = false
            this.matSnackBar.open(error.message, '', {
              duration: 5000,
            });
          },
        });
    }
  }

  formchange() {
    console.log('hello');
  }
}
