import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { ApiserviceService } from './apiservice.service';

@Injectable({
  providedIn: 'root',
})
export class ResolveService {
  constructor(
    public apiService: ApiserviceService,
    private cookieService: CookieService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    console.log('this is route data', route);

    let reqBody = route?.data?.['requestcondition'];
    if(route.queryParams['id']){
      reqBody['_id']=route.queryParams['id']
    }
    console.log(
      'route==========>',
      route.routeConfig,
      route.params['_id'],
      state.url
    );

    return new Promise((resolve, reject) => {
      console.log('route ------->', route);
      if (reqBody) {
        if (route?.data?.['crossdomain']) {
          this.apiService
            .getHttpDataPostWithoutBaseUrl(route?.data?.['endpoint'], reqBody)
            .subscribe({
              next: (res: any) => {
                console.log('API Data --------->', res);
                return resolve(res);
                // if (res?.status && res?.status == 'success') return resolve(res);
                // else return true;
              },
              error: (error: any) => {
                console.log('error --------->', error);
                return reject(error);
              },
            });
        } else {

          this.apiService
            .getHttpDataPost(route?.data?.['endpoint'], reqBody)
            .subscribe({
              next: (res: any) => {
                console.log('API Data --------->', res);
                return resolve(res);
                // if (res?.status && res?.status == 'success') return resolve(res);
                // else return true;
              },
              error: (error: any) => {
                console.log('error --------->', error);
                return reject(error);
              },
            });
        }
      } else {
        this.apiService.getHttpData(route?.data?.['endpoint']).subscribe({
          next: (res: any) => {
            console.log('API Data --------->', res);
            return resolve(res);
            // if (res?.status && res?.status == 'success') return resolve(res);
            // else return true;
          },
          error: (error: any) => {
            console.log('error --------->', error);
            return reject(error);
          },
        });
      }
    });
    return new Promise((resolve, reject) => {
      console.log('route ------->', route);
      if (reqBody) {
        if (route?.data?.['crossdomain']) {
        } else {
          this.apiService
            .getHttpDataPost(route?.data?.['endpoint'], reqBody)
            .subscribe({
              next: (res: any) => {
                console.log('API Data --------->', res);
                return resolve(res);
                // if (res?.status && res?.status == 'success') return resolve(res);
                // else return true;
              },
              error: (error: any) => {
                console.log('error --------->', error);
                return reject(error);
              },
            });
        }
      } else {
        this.apiService.getHttpData(route?.data?.['endpoint']).subscribe({
          next: (res: any) => {
            console.log('API Data --------->', res);
            return resolve(res);
            // if (res?.status && res?.status == 'success') return resolve(res);
            // else return true;
          },
          error: (error: any) => {
            console.log('error --------->', error);
            return reject(error);
          },
        });
      }
    });
  }
}
